import styled from '@emotion/styled';

export const StyledContactPage = styled.section`
  .form {
    width: 30%;
    margin: 40px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .title,
  .subtitle {
    color: #054752;
    text-align: center;
    font-family: 'Lora', Georgia, 'Times New Roman', Times, serif;
    font-weight: 400;
  }
  .title {
    width: 400px;
    margin: 80px auto 40px;
    font-size: 35px;
    font-weight: 600;
    position: relative;
  }
  .shape {
    height: 105px;
    width: 105px;
    position: absolute;
    bottom: -28px;
    right: -22px;
    z-index: -1;
  }
  .input {
    outline: none;
    border-color: #535f61;
    border-width: 0 0 2px 0;
    border-style: none none solid none;
    margin-bottom: 3rem;
    color: #535f61;
    padding: 1rem;
    font-family: 'Montserrat';
  }
  .button {
    background: '0 0 #ffffff';
    border: 1px solid #2f323a;
    border-style: none none solid none;
    border-radius: 0;
    font-size: 16px;
    line-height: inherit;
    margin: 0 auto;
    padding: 10px 50px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    color: #535f61;
    transition: all 0.4s ease;
  }

  .button:hover,
  .input:hover,
  .input:focus {
    color: #d79a2b;
    border-color: #d79a2b;
  }
  @media screen and (max-width: 768px) {
    .form {
      width: 90%;
    }
  }
  @media screen and (max-width: 560px) {
    .form {
      width: 90%;
    }
    .shape {
      height: 80px;
      width: 80px;
      bottom: -24px;
      right: -20px;
    }
    .title {
      margin: 50px auto 40px;
      font-size: 25px;
      width: 280px;
    }
  }
`