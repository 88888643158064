/** @jsx jsx */
import { Divider, jsx} from 'theme-ui'
import styles from "./color-divider.style";

type Props = {
  color: any;
};

const ColorDivider: React.FC<Props> = ({ color }) => {
  return (
    <Divider sx={styles.divider} className={color} />
  );
};

export default ColorDivider;