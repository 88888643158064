import React from "react";
import SEO from "../components/seo";
import PrimaryLayout from "../components/layout/primary/primary";
import ContentContainer from "../components/container/content-container";
import ColorDivider from "../components/color-divider/color-divider";
import { StyledContactPage } from "../components/contact/contact.style";
import pinkShape from "../images/pink_shape.png";

const AboutPage: React.FC<{}> = () => {
  return (
    <>
      <PrimaryLayout>
        <SEO title="Contact" />
        <ContentContainer width="100%">
          <StyledContactPage>
            <h1 className="title">
              have some questions?
              <img className="shape" src={pinkShape} />
            </h1>
            <h3 className="subtitle">Please feel free to fill out this form</h3>
            <form
              action="/thankyou"
              className="form"
              method="POST"
              name="Contact"
              data-netlify="true"
            >
              <input
                className="input"
                type="hidden"
                name="form-name"
                value="Contact"
              />
              <label htmlFor="name"></label>
              <input
                className="input"
                type="text"
                required={true}
                placeholder="Your name..."
                name="name"
              />
              <label htmlFor="email"></label>
              <input
                className="input"
                type="email"
                required={true}
                placeholder="Your email..."
                name="contactEmail"
                id="contactEmail"
              />
              <label htmlFor="message"></label>
              <textarea
                className="input"
                required={true}
                placeholder="Your message"
                name="message"
                id="message"
              />
              <button className="button" type="submit">
                Send
              </button>
            </form>
          </StyledContactPage>
          <ColorDivider color="cgMustard" />
        </ContentContainer>
      </PrimaryLayout>
    </>
  );
};
export default AboutPage;
