const styles = {
  divider: {
    borderBottom: "3px solid !important",
    padding: "15px 0",
    '&.cgFushia': {
      color: 'cgFushia'
    },
    '&.cgMustard': {
      color: 'cgMustard'
    },
    '&.cgLime': {
      color: 'cgLime'
    },
    '&.cgTeal': {
      color: 'cgTeal'
    },
    '&.cgGold': {
      color: 'cgGold'
    },
    '&.white': {
      color: '#fff'
    },
    '@media only screen and (min-width: 560px)': {
      padding: "30px 0",
    },
  }
}

export default styles;